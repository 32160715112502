import React, { useState, useEffect, useCallback } from "react"
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import queryString from 'query-string';
import { useQuery } from 'react-query';
import { repositoryConfigs } from '../../utils/prismic-previews';
import { Layout } from "../../components/global/layout";
import { Masthead } from "../../components/layout/masthead"
import { checkExpertProfile } from "../../api/sage";
import AdvisorExpressInterest from "../../components/forms/advisor_express_interest";
import ExpressInterestImg from '../../images/express-interest.jpg';

const AdvisorsExpressInterest = ({  }) => {
    const {isLoading, isError, isSuccess, data } = useQuery(
        'check-expert-profile',
        () => {
            const signalId = queryString.parse(window.location.search).signal_id;

            if (!signalId) {
                throw new Error('Signal ID is required');
            }

            return checkExpertProfile(signalId);
        },
        {
            retries: 3,
            staleTime: 10 * (60 * 1000), // 10 mins
            cacheTime: 15 * (60 * 1000), // 15 mins
        }
    );

    let isSubmitted = false;
    if (data) {
        isSubmitted = data.is_submitted;
    }

    const [ signalId, setSignalId ] = useState(null);
    useEffect(() => {
        const signalId = queryString.parse(window.location.search).signal_id;
        setSignalId(signalId);
    }, []);

    return (
        <Layout pageTitle={`Expression of interest`}
                metaTitle={'Expression of interest'}
        >
            <Masthead subtitle={''} title={'Expression of interest'} summary={'Matching your expertise to the R&D funding proposals'} />
            
            {(isLoading) && <p>Loading...</p>}
            {(isError) && <p>Error: Invalid URL. Please get in touch with your point of contact.</p>}
            {isSuccess && !isLoading &&
                <>
                    <AdvisorExpressInterest image={ExpressInterestImg} signalId={signalId} infoSubmitted={isSubmitted} />
                </>
            }
        </Layout>
    )
}

export default withPrismicPreview(AdvisorsExpressInterest, repositoryConfigs)

