import React, { useState, useRef } from "react"
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { useMutation } from "react-query";
import { expressInterest } from "../../api/sage";


export const AdvisorExpressInterest = ({ image, signalId, infoSubmitted }) => {
    const { register, handleSubmit, control, formState: { errors }, setError } = useForm();
    const [state, setState] = useState({
        isSubmitted: false,
      });

    const formRef = useRef(null);

    const expressInterestMutation = useMutation((data) => {
        return expressInterest(data);
    }, {
        onSuccess: () => {
            setState({isSubmitted: true});
            formRef.current.scrollIntoView();
        }
    });

    const onSubmit = (data) => {
        data['signal_id'] = signalId;
        data['consent'] = data['consent'] ? 'Yes' : 'No';

        expressInterestMutation.mutate(data);
    }

    const relevantExperienceExample = "- >30 years of applied R&D experience with aqueous chemistry of metal solutions for recovery and refining (hydrometallurgy)\n\n- Development, design and operation of large scale electrorefining and electrowinning plants for Cu, Ni, Co – at all stages of development – from lab scale to mini-plant, demonstration plant and commercialization\n\n- Involved with metal (e.g., Ni, Co) sulfate production for use in Li-ion EV batteries and development of technologies for electrochemical salt splitting\n\n- PhD in Electrochemistry, focusing on H2 water electrolysis."

    return (
        <>
            <div className="c-register" ref={formRef}>
                <div className="container">
                    <div className="row">
                        <div className="c-register__media-container">
                            <img src={image !== null ? image : "/images/test/contact.png"} alt="" className="c-register__media" loading="lazy" />
                        </div>
                        <div className="c-register__form">
                            <div className="f-reg-steps">
                                <ul>
                                    <li className={classNames("f-reg-step", {"f-reg-step--active": true})}>
                                        <span className={classNames("f-reg-step__bar")} />
                                        Expression of Interest
                                    </li>
                                </ul>
                            </div>
                            <div className="f-section">
                                <h3>Personal Details</h3>
                                <form className="c-form" onSubmit={handleSubmit(onSubmit)}>
                                    {(state.isSubmitted || infoSubmitted) && (
                                        <p>
                                            Thank you for your interest. The next steps are for us to share your relevant experience 
                                            with the client so that they can select preferred reviewers.
                                            <br /><br />
                                            We’ll be in touch shortly.
                                        </p>
                                    )}

                                    {!state.isSubmitted && !infoSubmitted && (
                                        <>
                                            <div className="row">
                                                <div className="f-field">
                                                    <div className={classNames("f-field__elem",{"f-field__elem--has-error": errors.first_name})}>
                                                        <label htmlFor="first_name">First Name</label>
                                                        <input
                                                            name="first_name" className="f-control" type="text"
                                                            placeholder="Please enter your first name(s)"
                                                            {...register("first_name", { required: "This field is required" })}
                                                        />
                                                        {errors.first_name && <span className="f-error">{errors.first_name.message}</span>}
                                                    </div>
                                                </div>

                                                <div className="f-field">
                                                    <div className={classNames("f-field__elem",{"f-field__elem--has-error": errors.surname})}>
                                                        <label htmlFor="surname">Surname</label>
                                                        <input
                                                            name="surname" className="f-control" type="text"
                                                            placeholder="Please enter your surname"
                                                            {...register("surname", { required: "This field is required" })}
                                                        />
                                                        {errors.surname && <span className="f-error">{errors.surname.message}</span>}
                                                    </div>
                                                </div>

                                                <div className="f-field">
                                                    <div className={classNames("f-field__elem",{"f-field__elem--has-error": errors.email})}>
                                                        <label htmlFor="email">Preferred Email</label>
                                                        <input
                                                            name="email" className="f-control" type="text"
                                                            placeholder="Please enter your email address"
                                                            {...register("email", { required: "This field is required", pattern: {value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: "Enter a valid email address."}})}
                                                        />
                                                        {errors.email && <span className="f-error">{errors.email.message}</span>}
                                                    </div>
                                                </div>

                                                <div className="f-field">
                                                    <div className={classNames("f-field__elem",{"f-field__elem--has-error": errors.phone})}>
                                                        <label htmlFor="phone">Phone (optional)</label>
                                                        <input
                                                            name="phone" className="f-control" type="text"
                                                            placeholder="Please enter your phone number"
                                                            {...register("phone")}
                                                        />
                                                        {errors.phone && <span className="f-error">{errors.phone.message}</span>}
                                                    </div>
                                                </div>

                                                <div className="f-field f-field--full">
                                                    <div className={classNames("f-field__elem",{"f-field__elem--has-error": errors.bio_url})}>
                                                        <label htmlFor="bio_url">Please share a URL to your Linkedin or online profile</label>
                                                        <input
                                                            name="bio_url" className="f-control" type="text"
                                                            placeholder="Please enter url to your Linkedin/online profile"
                                                            {...register("bio_url", { required: "This field is required" })}
                                                        />
                                                        {errors.bio_url && <span className="f-error">{errors.bio_url.message}</span>}
                                                    </div>
                                                </div>

                                                <div className="f-field f-field--full">
                                                    <div className={classNames("f-field__elem",{"f-field__elem--has-error": errors.short_bio})}>
                                                        <label htmlFor="short_bio">Please share 3-4 bullet points on your relevant experience (aligned to the proposal)</label>
                                                        <textarea name="short_bio" className="f-control f-control--experience"
                                                            placeholder={relevantExperienceExample}
                                                            {...register("short_bio", { required: "This field is required" })}
                                                        />
                                                        {errors.short_bio && <span className="f-error">{errors.short_bio.message}</span>}
                                                    </div>
                                                </div>

                                                
                                                <div className="f-field f-field--full">
                                                    <div className={classNames("f-field__elem checkbox",{"f-field__elem--has-error": errors.agree_terms}, "tcs")}>
                                                        <input
                                                            id="consent"
                                                            className="f-control"
                                                            type="checkbox"
                                                            {...register("consent", {required: "This field is required"})}
                                                        />
                                                        <label htmlFor="consent">
                                                            I give consent for my email to be shared with the funding body (RDIA)
                                                        </label>
                                                        {errors.consent && <span className="f-error">{errors.consent.message}</span>}
                                                </div>
                                                </div>

                                                <div className="f-field f-field--full">
                                                    <button
                                                        type="submit"
                                                        className="c-btn c-btn--large c-btn--primary"
                                                        disabled={expressInterestMutation.isLoading}
                                                    >
                                                        SUBMIT
                                                    </button>
                                                    {expressInterestMutation.isLoading && <p>Loading...</p>}
                                                    {expressInterestMutation.isError && <span className="f-error">An error has occurred. Please try again.</span>}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default AdvisorExpressInterest;