async function getExpert(token) {
    const response = await fetch(`${ process.env.GATSBY_SAGE_API_URL }/expert-review/?token=${ token }`, {
        method: 'GET',
    });

    const data = await response.json();

    const { expert, proposal } = data;

    if (!expert || !proposal) {
        throw new Error('Invalid expert data');
    }

    return data;
}

async function agreeConfidentiality(token) {
    const response = await fetch(`${ process.env.GATSBY_SAGE_API_URL }/agree-confidentiality/?token=${ token }`, {
        method: 'POST',
        contentType: 'application/json',
    });

    return await response.json();
}

async function updateExpertProfile(token, id) {
    const response = await fetch(`${ process.env.GATSBY_SAGE_API_URL }/update-advisor-profile/?token=${ token }&profile_id=${ id }`, {
        method: 'POST',
        contentType: 'application/json',
    });

    return await response.json();
}

async function logResponse(token, res) {
    const response = await fetch(`${ process.env.GATSBY_SAGE_API_URL }/log-response/?token=${ token }&response=${ res }`, {
        method: 'POST',
        contentType: 'application/json',
    });

    return await response.json();
}


async function agreeCoi(token) {
    const response = await fetch(`${ process.env.GATSBY_SAGE_API_URL }/coi/?token=${ token }`, {
        method: 'POST',
        contentType: 'application/json',
    });

    return await response.json();
}

async function submitProposalReview(token) {
    const response = await fetch(`${ process.env.GATSBY_SAGE_API_URL }/submit-to-oi/?token=${ token }`,
        {
            method: 'POST',
            contentType: 'application/json',
        });

    return await response.json();
}


async function checkExpertProfile(signalId) {
    const response = await fetch(`${ process.env.GATSBY_SAGE_API_URL }/check-expert-profile?signal_id=${ signalId }`, {
        method: 'GET',
        contentType: 'application/json',
    });

    if (response.status >= 300) {
        throw new Error('Invalid URL. Please get in touch with your point of contact.');
    }

    return await response.json();
}

async function expressInterest(data) {
    const response = await fetch(`${ process.env.GATSBY_SAGE_API_URL }/express-interest`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    if (response.status >= 300) {
        throw new Error('An error occurred. Please try again.');
    }

    return await response.json();
}


export { 
    getExpert, updateExpertProfile, agreeConfidentiality, 
    agreeCoi, submitProposalReview, logResponse,
    expressInterest, checkExpertProfile
};